import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';

export const MainListItems = () => {
  const navigate = useNavigate();
  const navigatePath = (path: string) => {
    navigate(path);
  };
  return (
    <React.Fragment>
      <ListItemButton onClick={() => navigatePath('/auth')}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton onClick={() => navigatePath('/auth/reports')}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Tapahtumat" />
      </ListItemButton>
      <ListItemButton onClick={() => navigatePath('/auth/time')}>
        <ListItemIcon>
          <AccessAlarmsIcon />
        </ListItemIcon>
        <ListItemText primary="Aikataulut" />
      </ListItemButton>
      <ListItemButton onClick={() => navigatePath('/auth/factors')}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Kertoimet" />
      </ListItemButton>
    </React.Fragment>
  );
};

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Jotain muuta jos tarvi
    </ListSubheader>
  </React.Fragment>
);
