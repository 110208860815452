export const formatDate = (date: string | Date) => {
  // Create a Date object from the UTC timestamp
  const utcDate = new Date(date);

  // Format the date part in the user's local format
  const localDate = utcDate.toLocaleDateString();

  // Get the UTC time as hh:mm:ss
  const utcTime = utcDate.toUTCString().split(' ')[4];

  // Combine the local date and UTC time
  return `${localDate} ${utcTime} UTC`;
};
