import React from 'react';
import { Period, Time } from '../../interfaces';
import { TableCell } from '@mui/material';

export const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const PeriodStatus = ({ period, row = false }: { period?: Partial<Period>, row?: boolean }) => {
  if (!period) return <></>;
  const timeSchedule = (time: Time[]) => {
    return time.map((time, idx) => (
      <span key={`time-${idx}`}>
        {' '}
        {time.start}-{time.end} UTC |
      </span>
    ));
  };

  if (row) {
    return <>{Object.entries(period).map(([key, value], idx) => (
      <TableCell>
        {days[parseInt(key)]}: 
        <ul style={{ margin: 0 }}>
          <li key={'time-' + idx}>
            {timeSchedule(value)}
          </li>
        </ul>
      </TableCell>
    ))}</>
  }

  return (
    <ul style={{ margin: 0 }}>
      {Object.entries(period).map(([key, value], idx) => (
        <li key={'time-' + idx}>
          {days[parseInt(key)]}: {timeSchedule(value)}
        </li>
      ))}
    </ul>
  );
};
export default PeriodStatus;
