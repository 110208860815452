import * as React from 'react';
import Title from './Title';
import { updateOnline } from '../RestApi';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Alert, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

export default function Online() {
  const [value, setValue] = React.useState('*');
  const [success, setSuccess] = React.useState<string | null>(null);

  const [error, setError] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleOnlineSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const target = formData.get('target') as string;
    const online = formData.get('select-status') as unknown as boolean;

    const hasTarget = !!target && value === 'other';

    const targetName = hasTarget ? target : '*';

    updateOnline(targetName, online)
      .then((res) => {
        setSuccess(res.message);
        setError(false);
      })
      .catch((err) => {
        setError(true);
        setSuccess(null);
      });

    event.currentTarget.reset();
  };

  return (
    <React.Fragment>
      {success && <Alert severity="success">{success}</Alert>}
      {error && <Alert severity="error">Updating failed</Alert>}
      <Title>Aseta online/offline</Title>
      <Box
        component="form"
        onSubmit={handleOnlineSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}>
        <FormControl>
          <FormLabel id="select-target">Set all or specific target</FormLabel>
          <RadioGroup
            aria-labelledby="select-target"
            defaultValue="*"
            name="select-radio"
            value={value}
            onChange={handleChange}>
            <FormControlLabel value="*" control={<Radio />} label="All" />
            <FormControlLabel value="other" control={<Radio />} label="One" />
          </RadioGroup>
          {value === 'other' && (
            <TextField
              margin="normal"
              fullWidth
              id="target"
              label="Target"
              name="target"
              autoComplete="target"
              autoFocus
            />
          )}
        </FormControl>
        <FormControl>
          <FormLabel id="select-status-label">Change the status to</FormLabel>
          <RadioGroup
            aria-labelledby="select-status-label"
            defaultValue={true}
            id={'select-status'}
            name="select-status">
            <FormControlLabel value={true} control={<Radio />} label="Online" />
            <FormControlLabel value={false} control={<Radio />} label="Offline" />
          </RadioGroup>
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Change status
          </Button>
        </FormControl>
      </Box>
    </React.Fragment>
  );
}
