import * as React from 'react';
import Title from '../Title';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Alert } from '../../interfaces';
import { getLatestAlerts } from '../../RestApi';
import Button from '@mui/material/Button';
import { EventsTable } from './EventsTable';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';

export default function Events() {
  const [page, setPage] = React.useState<number>(0);
  const [list, setList] = React.useState<Alert[]>([]);
  const [limit, setLimit] = React.useState<number>(10);  // Variable limit
  const [keyFilter, setKeyFilter] = React.useState<string>('');  // Key filter
  const dataFetchedRef = React.useRef(false);
  const [status, setStatus] = React.useState<string | null>('loading');

  async function fetchData() {
    return await getLatestAlerts(false, page, limit, keyFilter).then((res) => {
      setList((prevState) => prevState.concat(res));
      setPage(page + limit);
    });
  }

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchData()
      .then(() => setStatus(null))
      .catch((err) => {
        setStatus(JSON.stringify(err));
      });
  }, [dataFetchedRef.current]);

  async function getMore(event: React.MouseEvent) {
    event.preventDefault();
    setStatus('loading');
    fetchData().then(() => setStatus(null));
  }

  const handleLimitChange = (event: SelectChangeEvent<number>) => {
    event.preventDefault();
    setLimit(event.target.value as number);
    setPage(0); // Reset page
    setList([]); // Clear current list
    dataFetchedRef.current = false; // Allow re-fetch
  };

  const handleKeyFilterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setPage(0); // Reset page
      setList([]); // Clear current list
      dataFetchedRef.current = false; // Allow re-fetch
      setStatus('loading');
      fetchData().then(() => setStatus(null));
    }
  };

  const isLoading = status === 'loading';
  const error = status !== 'loading' && status !== null;

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Title>History</Title>
        {isLoading ? (
          <p>..loading</p>
        ) : (
          <>
            {error && <p>{status}</p>}
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <FormControl sx={{ mb: 2}}>
                <InputLabel>Limit</InputLabel>
                <Select value={limit} onChange={handleLimitChange}>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Filter by Key (exact name)"
                variant="outlined"
                value={keyFilter}
                onChange={(e) => setKeyFilter(e.target.value)}  // Update keyFilter state
                onKeyDown={handleKeyFilterKeyPress}  // Trigger refresh on Enter
                sx={{ mb: 2, minWidth: 200 }}
              />
            </Box>
            <EventsTable list={list} />
            <Button color="primary" onClick={getMore} sx={{ mt: 3 }}>
              See more orders....
            </Button>
          </>
        )}
      </Paper>
    </Grid>
  );
}
