import * as React from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Login, User } from './interfaces';
import { login } from './RestApi';
import LoginPage from './LoginPage';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DashboardRoute from './dashboard/Dashboard';
import Events from './dashboard/Events/Events';
import Periods from './dashboard/Periods/Periods';
import Factors from './dashboard/Factors/Factors';

export function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}Annan Mestari Teos{new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<Layout />}>
          <Route index path="/" element={<LoginPage />} />
          <Route
            path="auth/*"
            element={
              <RequireAuth>
                <ProtectedPage />
              </RequireAuth>
            }>
            <Route
              path="reports"
              element={
                <RequireAuth>
                  <Events />
                </RequireAuth>
              }
            />
            <Route
              path="time"
              element={
                <RequireAuth>
                  <Periods />
                </RequireAuth>
              }
            />
            <Route
              path="factors"
              element={
                <RequireAuth>
                  <Factors />
                </RequireAuth>
              }
            />
          </Route>
        </Route>
      </Routes>
    </AuthProvider>
  );
}

const theme = createTheme();

function Layout() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Outlet />
    </ThemeProvider>
  );
}

interface AuthContextType {
  user: any;
  loginUser: (user: Login, callback: VoidFunction) => void;
  signoutUser: (callback: VoidFunction) => void;
  error: any;
}

const AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<User | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  const loginUser = (loginDetails: Login, callback: VoidFunction) => {
    return login(loginDetails)
      .then((res: any) => {
        setUser(res);
        setError(null);
        callback();
      })
      .catch((err) => setError(err.message));
  };

  const signoutUser = (callback: VoidFunction) => {
    setUser(null);
    return callback();
  };

  const value = { user, loginUser, signoutUser, error };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function AuthStatus() {
  const auth = useAuth();
  const navigate = useNavigate();

  if (!auth.user) {
    return (
      <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
        Et ole kirjautunut
      </Typography>
    );
  }

  return (
    <>
      <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
        Welcome {auth.user.username}!{' '}
      </Typography>
      <IconButton
        color="inherit"
        onClick={() => {
          auth.signoutUser(() => navigate('/'));
        }}>
        <ExitToAppIcon />
      </IconButton>
    </>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
function ProtectedPage() {
  return <DashboardRoute />;
}
