import * as React from 'react';
import Title from './Title';
import { getLatestStatusOf } from '../RestApi';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { AlertValue } from '../interfaces';
import { CurrentStatusDataRows } from './Events/CurrentStatus';

export default function TargetData() {
  const [current, setCurrent] = React.useState<AlertValue | null>(null);
  const [currentTarget, setCurrentTarget] = React.useState<string | null>(null);
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const target = formData.get('target') as string;
    setCurrentTarget(target);

    getLatestBatch(target).then((res) => setCurrent(res));
    event.currentTarget.reset();
  }

  return (
    <React.Fragment>
      <Title>Current status of the target</Title>
      <Box component="form" onSubmit={handleSubmit} sx={{ m: 1 }}>
        <Grid container spacing={2}>
          <Grid item sm={8}>
            <TextField
              margin="normal"
              fullWidth
              required
              id="target"
              label="Target"
              name="target"
              autoComplete="target"
              autoFocus
            />
          </Grid>
          <Grid item sm={3}>
            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              Get target data
            </Button>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{currentTarget}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{current && <CurrentStatusDataRows data={current} />}</TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

async function getLatestBatch(key: string) {
  return await getLatestStatusOf(key);
}
