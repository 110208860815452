import * as React from 'react';
import Title from '../Title';
import { updateDates } from '../../RestApi';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { days } from '../Events/PeriodStatus';
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { Time } from '../../interfaces';

function handlePeriodSubmit(event: React.FormEvent<HTMLFormElement>) {
  event.preventDefault();

  const formData = new FormData(event.currentTarget);
  const target = formData.get('target') as string;

  const dayValues: Record<string, Time[]> = {};

  formData.forEach((value, key) => {
    if (key === 'target') return;
    if (key.startsWith('day')) {
      const dayIdx = key.split('_')[1];
      dayValues[dayIdx] = [];
      return;
    }

    const [day, time, type] = key.split('_');
    if (type === 'start') {
      dayValues[day].push({ start: value as string, end: '' });
    } else {
      dayValues[day][Number(time)].end = value as string;
    }
  });

  updateDates({ target, days: dayValues })
    .then((res) => console.log(res))
    .catch((err) => console.error(err));
  event.currentTarget.reset();
}

function removePeriodSubmit(event: React.FormEvent<HTMLFormElement>) {
  event.preventDefault();

  const formData = new FormData(event.currentTarget);
  const target = formData.get('target') as string;

  updateDates({ target }).then((res) => console.log(res));
  event.currentTarget.reset();
}

export default function PeriodManager() {
  const [times, setTimes] = React.useState<Record<number, object[]>>({});

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setTimes({ ...times, [event.target.id]: [{ start: undefined, end: undefined }] });
    } else {
      delete times[Number(event.target.id)];
      setTimes(times);
    }
  };

  const addNewValue = (idx: number) => {
    setTimes({ ...times, [idx]: [...times[idx], { start: undefined, end: undefined }] });
  };

  return (
    <React.Fragment>
      <Title>Change the schedule of the target when alerts go through</Title>
      <Box component="form" onSubmit={handlePeriodSubmit}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="target"
          label="Kohde"
          name="target"
          autoComplete="target"
          autoFocus
        />
        <div>
          {days.map((day, idx) => (
            <FormGroup key={'day_' + idx}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={'day_' + idx}
                    id={idx.toString()}
                    checked={!!times[idx]?.length}
                    onChange={handleChange}
                  />
                }
                label={day}
              />
              {times[idx] && (
                <Grid container spacing={2} key={`time-${idx}`}>
                  {times[idx].map((time, timeIdx) => (
                    <>
                      <Grid container item xs={3} direction="column" key={`time-${timeIdx}`}>
                        <TextField
                          margin="normal"
                          id={`${idx}_${timeIdx}_start`}
                          label="Start in UTC"
                          name={`${idx}_${timeIdx}_start`}
                          autoFocus
                        />
                        <TextField
                          margin="normal"
                          id={`${idx}_${timeIdx}_end`}
                          label="End in UTC"
                          name={`${idx}_${timeIdx}_end`}
                          autoFocus
                        />
                      </Grid>
                    </>
                  ))}
                  <Button onClick={() => addNewValue(idx)}>+</Button>
                </Grid>
              )}
            </FormGroup>
          ))}
        </div>
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Set time when in use
        </Button>
      </Box>
      <Box component="form" onSubmit={removePeriodSubmit} sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          id="target"
          label="Kohde"
          name="target"
          autoComplete="target"
          autoFocus
        />
        <Button type="submit" variant="contained" sx={{ m: 3 }}>
          Remove all time schedules
        </Button>
      </Box>
    </React.Fragment>
  );
}
