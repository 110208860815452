import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AdditionalData, Alert } from '../../interfaces';
import CurrentStatus, { CurrentStatusHeader } from './CurrentStatus';
import IconButton from '@mui/material/IconButton';
import { Box, Collapse, TableContainer } from '@mui/material';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formatDate } from '../../utils';
import PeriodStatus from './PeriodStatus';
import { getAdditionalDataByAlertId } from '../../RestApi';

type ListProps = {
  list: Alert[];
};
export const EventsTable = ({ list }: ListProps) => {
  return (
    <TableContainer component={Box} style={{ overflowX: 'auto' }}>
      <Table size="small" sx={{ width: '100%' }}>
        <TableHead>
          <TableRow key={'header'}>
            <TableCell></TableCell>
            <TableCell>Key</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Event</TableCell>
            <TableCell>Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row) => (
            <Row row={row} key={row._id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function Row(props: { row: Alert }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [additionalData, setAdditionalData] = React.useState<AdditionalData>();

  const handleOpen = async () => {
    if (!additionalData) await fetchAdditionalData();
    setOpen(!open)
  }

  const fetchAdditionalData = async () => {
      getAdditionalDataByAlertId(row._id, row.period)
      .then((res) => {
        setAdditionalData(res);
      })
      .catch((err) => {
        console.error(err);
        setOpen(false);
      });
    
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          {row.event.type !== 'wave' &&
            <IconButton aria-label="expand row" size="small" onClick={handleOpen}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        </TableCell>
        <TableCell component="th" scope="row">
          {row.key}
        </TableCell>
        <TableCell>{formatDate(row.created_date)}</TableCell>
        <TableCell>
          {row.event.type} - {row.event.value} {row.event.current[0] && `| ch: ${row.event.current[0]} / max: ${row.event.current[1]}`}
        </TableCell>
        <TableCell style={{ whiteSpace: 'wrap' }}>{row.messages?.join('\n')}</TableCell>
      </TableRow>
      {additionalData ?       <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
              Cache value
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>Cache Value</TableCell>
                  <CurrentStatusHeader />
                </TableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                  <TableCell>Current</TableCell>
                  <CurrentStatus row={additionalData.current} />
                </TableRow>
                <TableRow>
                  <TableCell>Before</TableCell>
                  <CurrentStatus row={additionalData.before} />
                </TableRow>
                <TableRow>
                  <TableCell>Period</TableCell>
                  <PeriodStatus period={additionalData.period} row={true} />
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow> : <></>}
    </React.Fragment>
  );
}
