import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PeriodManager from './PeriodManager';

export default function Periods() {
  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column'
        }}>
        <PeriodManager />
      </Paper>
    </Grid>
  );
}
