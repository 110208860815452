export type Login = {
  username: string;
  password: string;
};

export type User = {
  username: string;
  isAuth: boolean;
};

export interface Time {
  start: string;
  end: string;
}

export type Day = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type Period = Record<Day, Time[]>;

export interface Alert {
  _id: string;
  created_date: Date;
  key: string;
  messages: string[];
  event: {
    type: string;
    value: string;
    current: number[];
  };
  period: string;
}

export interface AdditionalData {
  current: CurrentValue;
  before: CurrentValue;
  period: Period;
}

export type PriceDetail = ValueDetails & { open?: number };
export type PriceDetails = Record<number, PriceDetail>;

export interface ValueTargets {
  target?: Partial<Record<('sell' | 'buy'), PriceDetails>>;
  stoploss?: Partial<Record<('sell' | 'buy'), PriceDetails>>;
  trigger?: Partial<Record<('sell' | 'buy'), PriceDetails>>;
}

export interface AlertValue {
  online: boolean;
  period?: Partial<Period>;
  direction: DirectionLine;
  up: number;
  up_ask: number;
  up_bid: number;
  down: number;
  down_ask: number;
  down_bid: number;
  current: number;
  current_bid: number;
  current_ask: number;
  base_up: number | undefined;
  base_down: number | undefined;
  updated: Date | string;
  value_down: ValueTargets;
  value_up: ValueTargets;
  wave?: DirectionLine;
  tracer?: DirectionLine;
  timma?: DirectionLine;
  bvol?: DirectionLine;
  half?: DirectionLine;
}

export interface CurrentValue {
  online: boolean;
  directions: number[];
  current: number[];
  up_base_ask_bid: number[];
  down_base_ask_bid: number[];
  updated: Date | string;
  value_down: ValueTargets;
  value_up: ValueTargets;
}

// Reverse mapping for easy lookup by string
export const DirectionEnumReverse = {
  0: 'down',
  1: 'up',
  2: null
};


export type DirectionLine = 'up' | 'down' | number;

export type EventType = 'buy' | 'sell' | 'buylimit' | 'selllimit';

export type ValueDetails = Partial<Record<EventType, number[]>>;

export interface Factor {
  tp?: number;
  sl?: number;
  bt?: number;
  open?: number;
}

export type Factors = Record<string, Factor>;
